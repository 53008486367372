.header_wrapper {
  display: flex;
  flex-direction: column;
  font-family: 'IBM Plex Sans', sans-serif;
  background: white;
}

.header_container {
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0px 130px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.header_container2 {
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0px 28px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.logo_container {
  max-width: 45%;
  height: auto;
  padding: 20px 0px;
}

.logo {
  /* max-width: 100%;
  height: auto; */
  max-width: 240px;
  height: 48px;
  cursor: pointer;
}



.link_button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}

.account_button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  /* font-size: clamp(0.875rem, 0.8441rem + 0.1546vw, 1.25rem); */
  font-size: clamp(0.875rem, 0.8028rem + 0.3608vw, 1.75rem);
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}

.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_container ul {
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  /* min-width: 100px; */
  justify-content: space-between;
}

.nav_container ul li {
  font-size: clamp(1rem, 1.036rem + -0.179vw, 0.875rem);
  cursor: pointer;
}

.nav_container_hamburger {
  display: none;
}

/* Screen adjustments before specific brakepoints */

@media only screen and (max-width: 800px) {
  .header_container {
    margin: 0px 60px
  }

}


@media only screen and (max-width: 500px) {
  .nav_container {
    display: none;
  }

  .nav_container_hamburger {
    display: block;
    align-self: center;
    cursor: pointer;
  }

  .header_container {
    margin: 0px 25px
  }

  .logo_container {
    max-width: 60%;
  }
}

/* 2k monitors and above */
@media only screen and (min-width: 2560px) {
  .account_button {
    font-size: clamp(2.25rem, 2.3325rem + -0.4124vw, 1.25rem);
  }

  .logo_container {
    width: 100%;
    max-width: 600px;
    padding: 0;
    margin-top: 100px;
  }

  .logo {
    width: 100%;
    max-width: 600px;
  }
}