.product_cards_container {
  /* margin-left: 20vh;
  margin-right: 20vh; */
  margin: 0px 130px;
}

.product_cards_title {
  color: #1B1E1F;
  font-size: clamp(1rem, 0.429rem + 2.857vw, 3rem);
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin-top: 0px;
}

.product_cards_sub_title {
  color: #1B1E1F;
  font-size: clamp(1rem, 0.777rem + 0.595vw, 1.313rem);
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.105px;
  text-align: center;
  margin-top: 0px;
  opacity: 0.5;
  margin-bottom: 42px;
}



@media only screen and (max-width: 800px) {
  .product_cards_container {
    margin: 0px 60px;
  }

  .product_cards_title {
    text-align: start;
    font-size: clamp(2rem, 0.333rem + 5.333vw, 3rem);
  }

  .product_cards_sub_title {
    text-align: start;
    font-size: 21px;
  }
}


@media only screen and (max-width: 500px) {
  .product_cards_container {
    margin: 0px 25px;
  }
}