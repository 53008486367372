.users_status_container {
  display: flex;
  flex-direction: column;
  font-family: "IBM Plex Sans", sans-serif;
}

.users_status_count {
  color: var(--main-primary-normal, #1E9DF2);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 89.6px */
  letter-spacing: -1.28px;
  margin: 0px;
}

.users_status_title {
  color: var(--main-grey-grey-50, #8D8E8F);
  text-align: center;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  letter-spacing: -0.16px;
  max-width: 129px;
  height: 48px;
}