.sign_up_container {
  display: flex;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  background: white;
}

.text_side_container {
  display: flex;
  margin-left: 130px;
  margin-right: 12px;
  margin-top: 28px;
  flex-direction: column;
  justify-content: flex-start;
}

.text_side {
  width: 75%;
  height: auto;
  background: url('../../assets/img/register_background_1.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 130px;
  margin-top: -130px;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 42px;
}

.text_side img {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.text_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: clamp(1rem, 0.643rem + 1.786vw, 2.25rem);
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.64px;
  margin-top: 0px;
}

.text_sub_title {
  color: #1E9DF2;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.46px;
  margin: 0px;
}

.text_description {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
  margin: 6px 0px 24px 0px;
  max-width: 90%;
}

.form_side {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 72px 120px 0px 68px;
}


@media only screen and (max-width: 1000px) {
  .sign_up_container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .text_side {
    width: 100%;
  }

  .form_side {
    margin: 100px 130px 60px 130px;
    width: auto;
  }
}


@media only screen and (max-width: 800px) {
  .text_side_container {
    margin: 0px 60px;
  }

  .form_side {
    margin: 100px 60px 60px 60px;
  }
}

@media only screen and (max-width: 500px) {
  .text_side_container {
    margin: 0px 25px;
  }

  .form_side {
    margin: 100px 25px 60px 25px;
  }
}