.dashboard_home_container {
  display: flex;
  font-family: "IBM Plex Sans", sans-serif;
  padding-left: 48px;
  height: 100%;
  width: 100%;
  z-index: 9999;
}

.dashboard_home_left_side {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard_header {
  font-family: "IBM Plex Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.64px;
}

.dashboard_home_right_side {
  min-width: 274px;
  min-height: 100%;
  border-left: 1px solid #E8E8E9;
  overflow: auto;
}

.users_status_wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  padding-bottom: 10px;
  border-bottom: 10px;
  border-bottom: 1px solid #E8E8E9;
  padding-right: 50px;
}

.pipe_img {
  margin-top: -30px;
}

.add_news_container {
  /* border-top: 1px solid #E8E8E9; */
  margin-left: -48px;
  height: 88px;
  display: flex;
  /* background-image: url("../../assets/img/car.png"); */
}

.add_news_inner {
  display: flex;
  width: 95px;
  height: 50px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.projects_title {
  color: var(--main-grey-grey-90, #1B1E1F);
  /* H5 */
  font-family: IBM Plex Sans, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 32.2px */
  letter-spacing: -0.46px;
  text-align: center;
}