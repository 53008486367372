.dashboard_layout_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout_page {
  width: 100%;
  display: flex;
  background-color: white;
  border-top: 1px solid #E8E8E9;
  background: white;
}
