.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  &-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &-content {
    box-sizing: border-box;
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    min-width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .close {
      float: right;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: 'red';
        cursor: pointer;
      }
    }

    .button-row {
      max-width: 100%;
      margin-top: 20px;
    }
  }

  &.is-open {
    display: block;
  }
}
