.account_settings_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.users_main_content_account {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  max-width: 100%;
  margin: 0px 40px;
  z-index: 999;
  background-image: url("../../../assets/img/car_cover_image.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 80%;
}

.user_title_account {
  color: #1B1E1F;
  font-family: "IBM Plex Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.8px;
  letter-spacing: -0.64px;
  margin: 32px 0px 30px 0px;
}

.account_settings_footer_container {
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 42px;
  z-index: 998;
}

.form_container_users_account {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-auto-rows: minmax(auto);
  gap: 24px;
  justify-content: start;
  z-index: 999;
  padding-bottom: 24px;
}

.form_container_users_account .double_row {
  grid-row: span 2;
}