.project_card_container {
  border-top: 1px solid #E8E8E9;
  display: flex;
  justify-content: space-between;
  padding: 4px 0px 12px 0px;
  z-index: 99;
}

.project_card_container:hover {
  background-color: #EEFAFF;
}

.link_styles {
  text-decoration: none;
  width: 100%;
}

.archived {
  font-family: "IBM Plex Sans", sans-serif;
  text-align: center;
  padding: 5px 15px;
  width: fit-content;
  text-transform: capitalize;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  margin: 0px;
  border-radius: 2px;
}

.active {
  background-color: #EEFAFF;
  color: #43A5CB;
}

.disabled {
  background-color: #F4F4F4;
  color: #8D8E8F;
}