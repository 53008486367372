// firefox
.scroller {
  overflow-y: scroll;
  scrollbar-color: #008cf0 #00a0f0;
  scrollbar-width: thin;
}


.scroller::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #F5F5F5;
}

.scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
  -webkit-transform: scaleX(10);
  background-color: #F5F5F5;
}

.scroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

//:hover::-webkit-scrollbar {
//  width: 8px;
//  height: 8px;
//}

.scroller::-webkit-scrollbar-track,
.scroller::-webkit-scrollbar-corner {
  background: none;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #008cf0;
  border: none;
  box-shadow: none !important;
}

//
//:hover::-webkit-scrollbar-thumb {
//  background: rgba(100, 108, 122, 0.84);
//}

.scroller::-webkit-scrollbar-thumb {
  background: #008cf0;
}

.scroller:active::-webkit-scrollbar-thumb {
  background: #00a0f0;
}


