.update_project_container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

.update_project_footer {
  border-top: 1px solid #E8E8E9;
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.update_project_footer_inner_container {

  display: flex;
  width: 310px;
  justify-content: space-between;
  margin: 0px 44px;

}

.update_project_content {
  overflow: auto;
  flex: 1;
  margin-left: 40px;
  padding: 32px 0px;
}

.id_title {
  color: #8D8E8F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 16.5px */
  letter-spacing: 0.11px;
}

.id_value {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.header_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 32.2px */
  letter-spacing: -0.46px;
  margin-bottom: 0px;
}

.create_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 32.2px */
  letter-spacing: -0.46px;
  margin-bottom: 0px;
  margin-top: 0px;
}
