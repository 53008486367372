.file_manager_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  margin-bottom: 35px;
}

.file_management_container {
  display: flex;
  font-family: "IBM Plex Sans", sans-serif;
  padding: 0px 41px 0px 40px;
  min-height: 819px;
  flex-direction: column;
}

.file_selector_title {
  color: #8D8E8F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
}

.upload_button {
  color: #1E9DF2;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.16px;
  border: none;
  background-color: white;
  cursor: pointer;
}

.action_button {
  color: #1E9DF2;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  border: none;
  background-color: white;
  cursor: pointer;
}

.filters_title {
  color: #1B1E1F;
  font-family: IBM Plex Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}

.file_footer_section {
  /* border-top: 1px solid #E8E8E9; */
  /* border-left: 1px solid #E8E8E9; */
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  align-items: center;
  position: fixed;
  /* bottom: 0; */
  width: 100%;
  background: white;



  position: fixed;
  bottom: 0;
  /* height: 40px, */
  /* background: "white", */
  /* width: "100%", */
}