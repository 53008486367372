.hero_wrapper {
  background: url('../../../assets/img/landing_background.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 130px;
  margin-top: -132px;
  /* position: relative; */
  /* width: 100vw;
  height: 100vh; */
  /* min-height: 900px; */
}

.hero_content {
  /* margin: -5vh 20vh 0vh 20vh; */
  margin: -46px 130px 0px 130px;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 40vh; */
  padding-bottom: 360px;
}

.motcos_title {
  color: #1B1E1F;
  font-size: clamp(5rem, 3.571rem + 7.143vw, 10rem);
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
}

.motcos_text {
  font-size: clamp(1rem, 0.929rem + 0.357vw, 1.25rem);
  color: #1B1E1F;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;
  max-width: 290px;
  line-height: 160%;
  padding-bottom: 40px;
}

.button_container {
  max-width: 190px;
}

@media only screen and (max-width: 800px) {

  .hero_content {
    margin: -39.6px 60px 0px 60px;
  }


}


@media only screen and (max-width: 500px) {
  .hero_content {
    margin: -5vh 3vh 0vh 4vh;
    padding-bottom: 0vh;

  }

  .button_container {
    padding: 10vh 0vh;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }

  .title {
    font-size: 80px;
    align-self: center;

  }

  .text {
    align-self: center;
    font-size: 16px;
  }
}

/* This adjustment was made to ensure that the landing page img will be not cropped on screens with width higher the 1600px */
@media only screen and (min-width: 1700px) {
  .hero_wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
}

/* 2k monitors and above */
@media only screen and (min-width: 2560px) {
  .hero_wrapper {
    top:-100px;
  }
  .motcos_title {
    font-size: clamp(10rem, 9.1753rem + 4.1237vw, 20rem);
    margin-top: 350px;
  }

  .motcos_text {
    font-size: clamp(1.25rem, 1.1366rem + 0.567vw, 2.625rem);
    max-width: 600px;
  }
  #landingRegisterButton {
    font-size: clamp(1.0625rem, 0.9852rem + 0.3866vw, 2rem);
  }
  .button_container {
    max-width: clamp(11.875rem, 11.2049rem + 3.3505vw, 20rem);;
  }
}