.files_container {
  display: flex;
  font-family: "IBM Plex Sans", sans-serif;
  padding: 0px 41px 0px 40px;
  /* min-height: 819px; */
  flex-direction: column;
}

.files_footer {
  /* border-top: 1px solid #E8E8E9; */
  min-height: 80px;
  display: flex;
  /* justify-content: space-between; */
  padding: 0px 24px;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  width: 80%;
  /* background: white; */
  bottom: 0;
  /* max-width: 50%; */
}

.drop_zone {
  border: 1px solid #D1D2D2;
  border-style: dashed;
  border-radius: 4px;
  border-width: 2px;
  background-color: white;
  height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.drop_zone:hover {
  background-color: #f0f0f0;
}

.drag {
  background-color: #f0f0f0;
}

.settings_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.8px;
  letter-spacing: -0.64px;
  margin-bottom: 16px;
}

.settings_sub_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  margin: 0px;
}

.files_status_label {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
}