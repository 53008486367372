/* .user_profile_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  font-family: "IBM Plex Sans", sans-serif;
  width: 100%;
}

.user_profile_main {
  display: flex;
  z-index: 999;
  height: 879px;
} */

.user_profile_main_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  padding: 25px 0px 0px 40px;
  z-index: 99;
  position: relative;
  background-image: url("../../../assets/img/car_cover_image.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 80%;
}


.user_profile_projects_container {
  max-width: 274px;
  min-width: 274px;
  height: 100%;
  border-left: 1px solid #E8E8E9;
  align-self: flex-end;
  /* display: flex;
  flex-direction: column;
  flex: 1 1 auto; */
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.user_info_separator {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  padding: 0px 8px
}

.user_info_text {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
}

.user_info_date {
  color: #8D8E8F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  padding-left: 5px;
}

.input_container {
  display: grid;
  justify-content: start;
  padding-bottom: 24px;
  padding-right: 80px;
  grid-template-columns: repeat(auto-fit, 300px);
  gap: 24px;
}

.input_container .double_row {
  grid-row: span 2;
}

.user_profile_footer {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
}