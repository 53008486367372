.footer_root {
  background-color: #234;
  background: #234;
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;

}

.footer_logo_img {
  /* max-width: 100%;
  height: auto; */
  max-width: 240px;
  height: 48px;
  cursor: pointer;
}

.footer_inner {
  display: flex;
  padding: 40px 80px 40px 80px;
}

.footer_content {
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  width: 100%;

}

.contact_wrapper {
  display: flex;
  flex-direction: column;
}

.contact_title {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.12px;
  margin-top: 0px
}

.content_context {
  display: flex;
  align-items: center;
}

.content_text {
  font-size: clamp(1rem, 1rem + 0vw, 1rem);
  color: #FFF;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.08px;
}



@media only screen and (max-width: 800px) {
  .footer_inner {
    flex-direction: column;
  }

  .footer_content {
    padding-top: 2vh;
  }
}


@media only screen and (max-width: 500px) {
  .footer_inner {
    padding: 40px 20px;
  }
}