/* Regular weight */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local("IBMPlexSans-Regular"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf") format('truetype');
}

/* Italic style */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  src: local("IBMPlexSans-Italic"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-Italic.ttf") format('truetype');
}

/* Light weight */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  src: local("IBMPlexSans-Ligth"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-Light.ttf") format('truetype');
}

/* Light italic style */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  src: local("IBMPlexSans-LigthItalic"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-LightItalic.ttf") format('truetype');
}

/* Medium weight */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  src: local("IBMPlexSans-Medium"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-Medium.ttf") format('truetype');
}

/* Medium italic style */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  src: local("IBMPlexSans-MediumItalic"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-MediumItalic.ttf") format('truetype');
}

/* Semi-bold weight */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local("IBMPlexSans-SemiBold"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-SemiBold.ttf") format('truetype');
}

/* Semi-bold italic style */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  src: local("IBMPlexSans-SemiBoldItalic"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-SemiBoldItalic.ttf") format('truetype');
}

/* Bold weight */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local("IBMPlexSans-Bold"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-Bold.ttf") format('truetype');
}

/* Bold italic style */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  src: local("IBMPlexSans-BoldItalic"),
    url("./assets/fonts/IBMPlexSans/IBMPlexSans-BoldItalic.ttf") format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  /* For Firefox */
  /* overflow: scroll; */
  scrollbar-color: #008de4 #fff;
  scrollbar-width: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #147AAE;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1976d2;
}