.saving_container {
  background: url('../../../assets/img/Savings.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 100px 0px;
  min-height: 345px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.saving_wrapper {
  margin: 0px 130px;
  display: flex;
  flex-direction: column;
  width: 32%;
}

.saving_wrapper_sx {
  display: flex;
  flex-direction: column;
  display: none;
}

.savings_outter {
  display: flex;
  width: 100%;
  align-items: center;
}

.savings_title {
  color: #FFF;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.72px;
  font-size: clamp(1rem, 0.107rem + 2.381vw, 2.25rem);
}

.savings_outter img {
  height: 108px;
  width: 108px;
  background: center center;
}

.saving_content {
  color: #FFF;
  font-size: clamp(1rem, 1rem + 0vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 70%;
  letter-spacing: -0.32px;
  margin-top: 0px;
  margin-bottom: 24px;
}

@media only screen and (max-width: 800px) {
  .saving_wrapper {
    margin: 0px 60px;
    width: 45%;
    padding-top: 35px;
  }

  .saving_content {
    max-width: 80%;
  }

  .savings_outter img {
    height: 84px;
    width: 84px;
    margin-bottom: -35px;
  }

}


@media only screen and (max-width: 1100px) {
  .saving_wrapper {
    width: 40%;
  }
}


@media only screen and (max-width: 500px) {

  .saving_container {
    display: block;
    width: 100%;
    background: linear-gradient(#1E9DF2, #0F6EAE);
  }

  .saving_wrapper {
    display: none;
  }

  .saving_wrapper_sx {
    background: url('../../../assets/img/Chassis_sx.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    min-height: 345px;
    font-family: 'IBM Plex Sans', sans-serif;

  }

  .saving_wrapper_content_sx {
    padding: 40px 24px 0px 24px;
  }

  .saving_sx_title {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.64px;
  }

  .saving_sx_content {
    min-width: 100%;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.08px;
  }
}