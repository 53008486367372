.sidebar_container {
  min-width: 240px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-right: 1px solid #E8E8E9;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* Full height of viewport */
  overflow: auto;
  flex: 1;
  /* Hide overflow from parent */
}

.sidebar_navigation {
  display: flex;
  flex-direction: column;
}

.link {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  padding: 12px 40px;
  text-decoration: none;
  color: var(--main-grey-grey-90, #1B1E1F);

}

.link:hover {
  background-color: #EEFAFF;
}


.link_inner_container {
  display: flex;
  align-items: center;
}

.link2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  padding: 12px 40px;
  text-decoration: none;
  color: var(--main-grey-grey-90, #1B1E1F);

}

.link2:hover {
  /* background-color: transparent; */
}

.selected_link {
  background-color: #EEFAFF;
  border-left: 4px solid #1E9DF2;
}

.selected_link2 {
  background-color: #1E9DF2;
}


.layout_page {
  flex: 1;
  overflow: auto;
}
