.file_selection_root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-width: 115px;
}

.file_drawer_root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.file_selection_title {
  color: #8D8E8F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
}

.file_drawer_header_container {
  min-height: 53px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #D1D2D2;
  padding: 12px 12px 12px 28px;
}

.file_drawer_header_container_data {
  display: flex;
  align-items: center;
}

.file_drawer_footer {
  display: flex;
  min-height: 58px;
  border-top: 1px solid #D1D2D2;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 13px;
  padding-top: 10px;
}