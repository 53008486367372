.terms_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

}

.terms_inner_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 41px;
}

.terms_title {
  color: #1B1E1F;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.8px;
  letter-spacing: -0.64px;
  margin-top: 0px;
}

.terms_footer {
  border-top: 1px solid rgb(232, 232, 233);
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 41px;
  background-color: white;
  flex-shrink: 0;
}

.history_btn {
  cursor: pointer;
  border: none;
  color: #1E9DF2;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  width: 77px;
  height: 24px;
  align-self: flex-end;
  margin: 8px 40px 0px 0px;
  background-color: transparent;
}

.table_title {
  color: #1B1E1F;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.2px;
  letter-spacing: -0.46px;
}