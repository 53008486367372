.files_status {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.11px;
  /* width: 38px;
  height: 20px;
  padding: 2px 4px;
  border: 1px solid transparent;
  text-align: center;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0px; */
}

.box_container {
  display: flex;
  align-items: center;
  text-align: center;
}

.status_box {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 6px;
}

.provided {
  background: #A9F0B0
}

.requested {
  background: #FBE39A;
}

.required {
  background: #E8E8E9;
}

.declined {
  background: #F4A6A6;
}