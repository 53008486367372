.landing_container {
  font-family: 'IBM Plex Sans', sans-serif;
  background-color: white;
}

/* .fade-in-element {
  animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} */