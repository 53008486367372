.section_container_simulation {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0px 130px;

}

.section_wrapper_simulation {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 60%; */
  align-self: center;
}

.header_part {
  background-color: #EEF3FF;
  padding: 17px 17px 17px 116px;
}

.text_header {
  color: #4D69AC;
  font-size: clamp(0.75rem, 0.536rem + 1.071vw, 1.5rem);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.48px;
  margin: 0px;
}

.text_subheader {
  color: #4D69AC;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.48px;
  margin: 0px;
}

.text_inner_title {
  color: #4D69AC;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.48px;
  box-shadow: 0px 22.04347801208496px 44.08695602416992px 0px rgba(210, 223, 234, 0.30);
  padding-bottom: 25px;
  border-radius: 5.511px;
}

.mathlab_container {
  background: white;
  padding: 11px 14px;
  border-radius: 5px;
}


@media only screen and (max-width: 800px) {
  .section_wrapper_simulation {
    align-items: flex-start;
  }

  .section_container_simulation {
    margin: 0px 60px;

  }

  .header_part {
    padding: 17px 17px 17px 10px;
  }

  .mathlab_container {
    background: none;
    padding: 0px;
    border-radius: 5px;
    margin-right: -20px;
  }

  .mathlab_container img {
    width: 80%;
  }
}


@media only screen and (max-width: 500px) {
  .section_container_simulation {
    margin: 0px 25px;

  }

}