.project_container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  font-family: "IBM Plex Sans", sans-serif;
}

.project_container_navigation {
  display: flex;
  justify-content: space-between;
  padding: 28px 40px 18px 40px;
}


.project_footer {
  border-top: 1px solid #E8E8E9;
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.project_main_content {
  overflow: auto;
  flex: 1;
  padding: 0px 40px 68px 40px;
}

.project_main_name {
  color: var(--main-grey-grey-90, #1B1E1F);
  max-width: 529px;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.64px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.project_main_info_container {
  display: flex;
  width: 800px;
  justify-content: space-between;
}

.project_main_info_wrapper {
  display: flex;
  flex-direction: column;
}

div.project_main_info_wrapper p {
  color: var(--main-grey-grey-50, #8D8E8F);
  /* Secondary */
  font-family: IBM Plex Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 16.5px */
  letter-spacing: 0.11px;
  margin-bottom: 8px;
}

.table_title {
  color: #1B1E1F;

  /* H5 */
  font-family: IBM Plex Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 32.2px */
  letter-spacing: -0.46px;
}

.project_loading_content {
  height: 100%;
  margin: 0px 40px;
  display: flex;
  justify-content: space-around;
}