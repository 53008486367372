.files_card_container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F4F4F4;
  min-width: 572px;
  justify-content: space-between;
}

.files_card_status {
  width: 10px;
  height: 10px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.files_card_error {
  color: #D96251;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  margin: 0px;
}

.status_accepted {
  background: #A9F0B0;
}

.status_rejected {
  background: #F4A6A6;
}


.files_card_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  padding: 0px 24px 0px 8px;
}

.files_card_size {
  color: #8D8E8F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
}

.files_extension {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.11px;
  width: 38px;
  height: 20px;
  padding: 2px 4px;
  border: 1px solid transparent;
  text-align: center;
  border-radius: 2px;
  text-transform: uppercase;
  margin: 0px;
}

.txt {
  background: #FFFCF2;
  color: #A3976F
}

.pdf {
  background: #F2FFF9;
  color: #4BA86A
}

.svg {
  background: #F5F2FF;
  color: #7B6FA3
}

.png {
  background: #EEFAFF;
  color: #43A5CB
}

.csv {
  background: #FEF2FF;
  color: #A36FA1
}

.docx {
  background: #EEF3FF;
  color: #4D69AC
}

.xsl {
  background: #FFF2F2;
  color: #A36F6F
}