.register_form_field_container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.text_field_wrapper {
    display: flex;
    flex-direction: column;
}

.register_form_footer_containers {
    border-top: 1px solid rgb(232, 232, 233);
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 42px;
    z-index: 998;
}

.sign_up_concent_wrapper {
    display: flex;
}

.registration_title {
    color: #1B1E1F;
    font-size: clamp(1rem, 0.643rem + 1.786vw, 2.25rem);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.72px;
    margin-top: -50px;
}

.sign_up_privacy {
    max-width: 45%;
    margin-left: 10px;
}

.sign_up_terms {
    max-width: 45%;
    margin-right: 20px;
}

/* Custom phone input styles */
.phone-input-form .selected-flag {
    margin-top: 2px;
}


@media only screen and (max-width: 500px) {
    .sign_up_concent_wrapper {
        flex-direction: column;
    }

    .sign_up_privacy {
        max-width: 100%;
    }

    .sign_up_terms {
        max-width: 100%;
        margin-left: 10px;
        margin-right: 0px;
    }
    .termsCheckbox{
        margin-left: -5px !important;
        justify-content: flex-start !important;
    }
}