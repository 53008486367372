.container {
  display: flex;
  /* margin-bottom: 42px; */
  font-family: 'IBM Plex Sans', sans-serif;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.img_wrapper {
  min-width: 40%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.img_side_software {
  background: url('../../../assets/img/Software.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img_side_functions {
  background: url('../../../assets/img/Functions.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content_side {
  display: flex;
  flex-direction: column;
  margin: 40px 68px 68px 40px;
}

.content_side_title {
  color: #1B1E1F;
  font-size: clamp(1.5rem, 1.357rem + 0.714vw, 2rem);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.64px;
  max-width: 270px;
  margin-bottom: 16px;
}

.content_side_text {
  color: #1B1E1F;
  font-size: clamp(1rem, 1rem + 0vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.32px;
  max-width: 80%;
  padding: 2px 0px;
}


@media only screen and (max-width: 1100px) {

  .img_wrapper {
    min-height: 200px;
  }

  .container {
    display: flex;
    flex-direction: column;
    box-shadow: none;
  }

  .img_side_software {
    background: url('../../../assets/img/Software_tablet.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img_side_functions {
    background: url('../../../assets/img/Functions_tablet.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
  }

  .content_side_title {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .content_side {
    margin: 0px;
  }

}

@media only screen and (max-width: 360px) {

  .img_wrapper {
    min-height: 120px;
  }
  .img_side_software {
    background: url('../../../assets/img/Software_mobile.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 360px;
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    /* width: 100%; */
    height: 100%;
    /* object-fit: cover; */
  }

}