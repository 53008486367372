.video_section {
  margin: 0px 130px;
  padding-top: 60px;
  /* padding-bottom: 100px; */
}

.video_container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;

}

.video_container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  border: 1px solid transparent;
}


@media only screen and (max-width: 800px) {
  .video_section {
    margin: 0px 60px;
  }

}


@media only screen and (max-width: 500px) {
  .video_section {
    margin: 0px 25px;
  }
}