.ql-toolbar {
  background-color: #F4F4F4;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border: 1px solid #F4F4F4;
}

.ql-container {
  border: 1px solid #F4F4F4;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}