.section_container {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 60px; */
  padding-top: 100px;
  font-family: 'IBM Plex Sans', sans-serif;
  margin: 0px 130px;
}

.section_container_img {
  padding-top: 30px;
  height: auto;
  width: 100%;
  align-self: center;
  max-width: 900px;
}

.section_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 60%; */
  align-self: center;
}

.section_title {
  color: #1B1E1F;
  /* font-size: clamp(1rem, 0.429rem + 2.857vw, 3rem); */
  font-size: clamp(2rem, 1.714rem + 1.429vw, 3rem);
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.96px;
  margin: 0px;
}

.section_subTitle {
  color: #1B1E1F;
  text-align: center;
  font-size: clamp(1rem, 0.911rem + 0.446vw, 1.313rem);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.105px;
  opacity: 0.5;
  margin: 0vh 10vh;
  max-width: 100%;
}

.protected_img {
  width: 20px;
  height: 20px;
}

.behavioural_title {
  color: #1B1E1F;
  font-size: clamp(0.875rem, 0.839rem + 0.179vw, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.32px;
  margin: 0px;
  max-width: 300px;
}

.behavioural_wrapper {
  display: flex;
  align-items: center;
  background: #F1FFFC;
  margin-left: 10px;
}

.behavioural_text {
  color: #7B928D;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;
  /* margin: 0px; */
}

.all {
  background: transparent;
}

.aspice {
  background: #F4FCFF;
  padding-bottom: 50px;
}

.behavioural {
  padding-bottom: 100px;
  background: transparent;
}

@media only screen and (max-width: 800px) {
  .section_container {
    margin: 0px 60px;
  }

  .section_wrapper {
    align-items: flex-start;
  }

  .section_subTitle {
    margin: 0px;
    text-align: left;
    max-width: 100%;
  }

  .behavioural {
    background: #E8F9FF;
  }
}


@media only screen and (max-width: 500px) {
  .section_container {
    margin: 0px 25px;
    /* padding: 60px 0px 85px 0px; */
  }

  .section_title {
    font-size: 32px;
  }
}