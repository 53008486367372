.news_article h1,
.news_article h2,
.news_article h3,
.news_article h4,
.news_article h5,
.news_article h6,
.news_article p,
.news_article span,
.news_article li,
.news_article {
    color: #8D8E8F;
    font-family: "IBM Plex Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    min-height: 48px;
    max-height: calc(2*2em);
    /* padding-bottom: 24px; */
}

.card_container:hover {
    background-color: #EEFAFF;
}