.content_article {
    width: 900px;
    word-wrap: break-word;
}

.content_article>p {
    color: #1B1E1F;
}

.content_article>p>img {
    max-width: 900px;
}