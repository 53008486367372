.available_company_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-family: "IBM Plex Sans";
}

.no_companies_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  position: relative;
  z-index: 999;
  background-image: url("../../../assets/img/car_cover_image.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 80%;
}

.available_company_filter {
  display: flex;
  flex-direction: column;
  min-width: 276px;
  justify-content: space-between;
  padding: 32px 40px 0px 40px;
  border-right: 1px solid #E8E8E9;
}

.available_company_content_wrapper {
  display: flex;
  flex-direction: column;
  font-family: "IBM Plex Sans";
  height: 100%;
  width: 100%;
  border-left: 1px solid rgb(232, 232, 233);
  position: relative;
  justify-content: space-between;
}

.available_company_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.8px;
  letter-spacing: -0.64px;
  margin: 0px 0px 0px 40px;
  padding-bottom: 20px;
}

.available_company_actions {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
  padding: 32px 40px 0px 40px;
}

.available_company_back_button {
  display: flex;
  justify-content: flex-start;
}

.available_company_buttons {
  display: flex;
  min-width: 130px;
  justify-content: flex-end;
}

.available_company_info_section {
  display: flex;
  flex-direction: column;
}

.available_company_info_wrapper {
  display: flex;
}

.available_company_info_title {
  color: #1B1E1F;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  /* margin: 0px; */
}

.available_company_info_separator {
  color: #1B1E1F;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  margin: 10px 8px;
}

.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.date_formats {
  color: #8D8E8F;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  padding-right: 12px;
}

.date_value {
  margin: 0px;
}

.available_company_information_section {
  display: flex;
  padding-bottom: 32px;
  /* justify-content: space-evenly; */

}

.information_container {
  display: flex;
  flex-direction: column;
  max-width: 180px;
  padding-right: 32px;
}

.available_company_footer {
  min-height: 80px;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  padding: 0px 24px;
  z-index: 999;
  background-color: white;
  justify-content: space-between;
}

.available_company_footer_create {
  border-top: 1px solid rgb(232, 232, 233);
  min-height: 80px;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  padding: 0px 24px;
  z-index: 999;
  background-color: white;
  justify-content: flex-end;
  /* justify-content: space-between; */
}


/* FILTER STYLES */
.filter_title {
  color: #1B1E1F;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-bottom: 24px;
}

.team_title {
  color: #1B1E1F;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.2px;
  letter-spacing: -0.46px;
}