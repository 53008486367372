.no_content_container{
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%;
  background-image: url("../../../assets/img/car_cover_image.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 80%;
}

.no_content_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.8px;
  letter-spacing: -0.64px;
  margin-left: 41px;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 41px;
  z-index: 999;
}

.no_content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.no_content_inner {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.inner_title {
  color: #1B1E1F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.2px;
  letter-spacing: -0.46px;
  margin: 0px;
}

.inner_sub_text {
  color: #8D8E8F;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: 0.11px;
  margin: 0px;
  max-width: 200px;
  padding-top: 8px;
  padding-bottom: 24px;
}


.no_content_footer {
  min-height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 24px;
  z-index: 999;
  background-color: white;
}

.no_content_car_image {
  position: absolute;
  bottom: 50px;
  left: 220px;
  width: 100%;
  height: auto;
  opacity: 0.1;
  transform: rotate(-3.178deg);
}

/* .no_content_car_image2 {
  position: absolute;
  bottom: -90px;
  left: 220px;
  width: 100%;
  height: auto;
  opacity: 0.1;
  transform: rotate(-3.178deg);
  z-index: 1;
} */